@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .primary {
    @apply bg-teal-600 text-white;
  }
  button.primary:hover {
    @apply bg-teal-700 text-white bg-opacity-90;
  }

  .secondary {
    @apply bg-stone-800 text-white;
  }

  .default {
    @apply bg-gray-400 text-white;
  }

  .error {
    @apply bg-red-500 text-white;
  }

  .alert-error {
    @apply bg-red-200 text-red-700;
  }

  .alert-warning {
    @apply bg-orange-100 text-orange-700;
  }

  .not_selected {
    @apply text-yellow-700 bg-yellow-200
  }

  .inapt {
    @apply text-white bg-red-500
  }

  .not_rated {
    @apply text-gray-500 bg-gray-200
  }

  .selected {
    @apply text-white bg-green-400
  }

  .apt {
    @apply text-blue-800 bg-blue-100
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bdbdbd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(156, 156, 156);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(155, 155, 155);
}

/* Cor do texto do autocomplete */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px rgb(238, 238, 238) inset !important;
  -webkit-text-fill-color: rgb(141, 140, 140) !important;
}
